
@import '../../../styles/fonts.scss';
@import '../../../styles/variables';


.sgh-pay-over-time {
  font-size: 1.2rem;
  @screen mdr {
    font-size: 1.4rem;
  }
  &__button {
    @include primaryFont();
    line-height: 1.6rem;
    font-weight: 400;
    color: $mine-shaft;
    padding: 0;
    border: none;
    background: transparent;
  }

  .common__icon {
    &--info {
      height: 1.6rem;
      width: 1.6rem;
      position: relative;
      bottom: 0.17rem;
    }
  }

  &-custom-page {
    font-size: 1.4rem;
    padding-left: 0;
    text-align: center;
    @screen mdr {
      text-align: left;
    }
  }
  &-minicart-page {
    font-size: 1.2rem;
    padding-left: 0;
  }
  &-pdp {
    text-align: center;
    padding-left: 0;
  }
  .sgh-installments {
    @screen mdr {
      padding-left: 1.6rem;
    }
  }
}
.sgh-minicart-container-inner__content,
.custom-checkout,
.installment-container {
  .sgh-installments {
    @screen mdr {
      padding-left: 0;
    }
  }
}

/* Fix only for Desktop and only for cart when loylaty is active */
.loyalty-present {
  .cart-footer {
    &__section {
      .sgh-pay-over-time {
        &__button {
          @screen lg {
            text-align: left;
          }
        }
      }
    }
  }
}

.sgh-installments--brazil {
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-left: 0;
  text-align: left;
}

.installment-container {
  .sgh-pay-over-time-custom-page {
    @screen lgr{
      text-align: revert;
      .sgh-installments{
        padding-bottom:1.4rem;
      }
    }
  }
}

